<template>
  <div>
    <h1 class="-mt-3">INSTITUCE: 2500–3000 m2</h1>
    <div class="grid lg:grid-cols-2 lg:gap-8 no-mb-h1">
      <div>
        <div class="mb-6">
          <h1>1000 m2</h1>
          <p>Permanentní expozice – sbírkový fond</p>
        </div>
        <div class="mb-6"> 
          <h1>500 m2</h1>
          <p>Velký výstavní prostor</p>
        </div>
        <div class="mb-6">
          <h1>200 m2</h1>
          <p>Malý výstavní prostor</p>
        </div>
        <div class="mb-6">
          <h1>100 osob</h1>
          <p>Přednáškový prostor (pro větší přednášky a aktivity bude využíván společný multifunkční prostor Nákladového nádraží Žižkov)</p>
        </div>
        <div class="mb-6">
          <h1>20 m2</h1>
          <p>Badatelna</p>
        </div>
        <div class="mb-6">
          <h1>60 m2</h1>
          <p>Kavárna a zázemí</p>
        </div>
      </div>
      <div>
        <div class="mb-6">
          <h1>30 m2</h1>
          <p>Prodejna publikací včetně skladu</p>
        </div>
        <div class="mb-6">
          <h1>500 m2</h1>
          <p>Archív</p>
        </div>
        <div class="mb-6">
          <h1>200 m2</h1>
          <p>Administrativa, kuchyňka, sprchový kout, wc (cca 12 osob)</p>
        </div>
        <div class="mb-6">
          <h1>30 m2</h1>
          <p>Zasedací místnost pro 20 osob</p>
        </div>
        <div class="mb-6">
          <h1>50 m2</h1>
          <p>Zázemí</p>
        </div>
        <div class="mb-6">
          <h1>50 m2</h1>
          <p>Sociální zařízení</p>
        </div>
      </div>
    </div>
    <hr class="border-black mt-6 mb-8">
    <h1>FINANCOVÁNÍ</h1>
    <div class="grid lg:grid-cols-2 lg:gap-8">
      <div class="mb-6">
        <h1>VEŘEJNÝ SEKTOR</h1>
        <p>Ministerstvo kultury ČR, Hlavní město Praha, EU.</p>
      </div>
      <div class="mb-6">
        <h1>SOUKROMÝ SEKTOR</h1>
        <p>Central Group, Sekyra Group, Penta, další investoři</p>
      </div>
    </div>
    <hr class="border-black mt-6 mb-8">
    <h1>FORMA ORGANIZACE</h1>
    <div class="grid lg:grid-cols-2 lg:gap-8">
      <div class="mb-6">
        <p>Obecně prospěšná společnost</p>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  name: 'Instituce',
  components: {
  },
  mounted() {
    window.scrollTo({ top: 0});
  },
}
</script>

<style>
  .no-mb-h1 h1{
    margin-bottom: 0 !important;
  }
</style>
